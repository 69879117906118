/**
 * 充电桩模块
 */

import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

// 充电桩列表
export const getChargeWhitelist = (params = {}) => {
  return request.post(`${SERVER.ORDER_SERVICE}/api/v1/charging/qw/page`, params).then(thenResolve)
}

export const getRegionList = async (params = {}) => {
  return request.post(`${SERVER.ORDER_SERVICE}/api/v1/charging/region`, params).then(thenResolve)
}
