<template>
  <div id="charge-page" class="charge-page">
    <div class="title">
      {{ $t('安装位置查询') }}
    </div>
    <div class="prview">
      <img src="../../images/charge/charge.png" alt="">
    </div>
    <form action="#" class="search-container">
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        class="address"
        :placeholder="$t('城市')"
        clearable
        @click="show = true"
        
      >
        <template #right-icon>
          <van-icon v-if="fieldValue" name="close" @click.stop="handleClear" />
        </template>
      </van-field>
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          :title="$t('城市')"
          :options="options"
          @close="show = false"
          @change="onChange"
          @finish="onFinish"
        />
      </van-popup>
      <van-search
        v-model.trim="searchForm.address"
        :maxlength="maxlength"
        clearable
        class="search-input"
        left-icon=""
        clear-trigger="always"
        :placeholder="$t('输入地址查询')"
        @search="onSearch"
        @clear="onSearch"
      >
      <template #right-icon>
        <van-icon name="search" @click="onSearch" />
      </template>
    </van-search>
    </form>
    <div class="list-wrapper">
      <van-list
        v-model="loading"
        class="list"
        :finished="finished"
        offset="50"
        @load="onLoad"
        >
        <div class="ul">
          <div v-for="(item, index) in list" :key="index" class="item">
            <div class="left">
              <div class="address">{{ item.address }}</div>
              <div class="meta">
                <span class="province">{{ item.provinceName }}</span>
                <span class="province">{{ item.cityName }}</span>
                <span class="province">{{ item.countyName }}</span>
              </div>
            </div>
            <div class="right">
              {{ item.num }}
            </div>
          </div>
        </div>
        <template #finished>
            <template v-if="list.length == 0">
              <van-empty class="empty" image-size="100">
                <template #description>
                  <p>{{ $t('非常抱歉') }}</p>
                  <p>{{ $t('该地区暂不支持充电桩安装服务') }}</p>
                </template>
              </van-empty>
            </template>
            <span v-else>{{ i18n.t('没有更多了') }}</span>
          </template>
      </van-list>
    </div>
  </div>
</template>
<script>
import { getChargeWhitelist, getRegionList } from '@/services/charge'
import List from '@/components/baseList/list'
import AddressSystem from '@/components/addressSystem.vue'
import { i18n } from '@/main'

export default {
  components: {
    List,
    AddressSystem
  },
  data(){
    return {
      i18n,
      show: false,
      fieldValue: '',
      cascaderValue: '',
      options: [],
      list: [],
      searchForm: {
        address: '',
        provinceName: '',
        cityName: '',
        countyName: '',
        type: 1,
        currentPage: 1,
        pageSize: 10
      },
      loading: false,
      finished: false,
      totalCount: 0,
      maxlength: 50,
      provinceIndex: null,
    }
  },
  async created() {
    this.options = await this.handleFetchRegion()
  },
  methods:{
    handleClear() {
      this.searchForm.provinceName = ''
      this.searchForm.cityName = ''
      this.searchForm.countyName = ''
      this.provinceIndex = null
      this.fieldValue = ''
      this.cascaderValue = ''
      this.handleFetchRegion()
      this.onSearch()
    },
    async handleFetchRegion() {
      const reqData = {
        provinceName: this.searchForm.provinceName,
        cityName: this.searchForm.cityName,
        pageNum: 1,
        pageSize: 100,
        type: this.searchForm.type
      }
      const res = await getRegionList(reqData)
      if (this.searchForm.type !== 3) {
        return res.dataList.map((c) => ({ text: c, value: c, children: [] }))
      } else {
        return res.dataList.map((c) => ({ text: c, value: c }))
      }
    },
    async onChange({ value, selectedOptions, tabIndex }) {
      // console.log(value, tabIndex, 'value')
      if (tabIndex === 0) {
        // 市
        const currentIndex = this.options.findIndex((c) => c.value === value)
        this.searchForm.type = 2
        this.searchForm.provinceName = value
        this.searchForm.cityName = ''
        const list = await this.handleFetchRegion()
        if (currentIndex !== -1) {
          this.provinceIndex = currentIndex
          this.$set(this.options[currentIndex], 'children', list)
        }
      } else if (tabIndex === 1) {
        // 区
        this.searchForm.type = 3
        this.searchForm.cityName = value
        const currentIndex = this.options[this.provinceIndex].children.findIndex(c => c.text === value)
        const list = await this.handleFetchRegion()
        if (currentIndex !== -1) {
          this.cityIndex = currentIndex
          this.$set(this.options[this.provinceIndex].children[currentIndex], 'children', list)
        }
      } else if (tabIndex === 2) {
        this.searchForm.countyName = value
      }
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false
      const obj = selectedOptions.pop()
      if (obj) {
        this.fieldValue = obj.text
      }
      this.onSearch()
    },
    // 重置
    onSearch() {
      this.searchForm.currentPage = 1
      this.list = []
      this.onLoad()
    },
    onLoad() {
      this.fetchList()
    },

    async fetchList() {
      const { address, provinceName, cityName, countyName, currentPage, pageSize } = this.searchForm
      const res = await getChargeWhitelist({
        pageNum: currentPage,
        pageSize: pageSize,
        address,
        provinceName,
        cityName,
        countyName
      })
      if (!res) {
        this.finished = true
        return true
      }
      if (currentPage == 1) {
        this.list = res.dataList
      } else {
        this.list = this.list.concat(res.dataList)
      }
      this.loading = false

      this.totalCount = res.page.totalCount
      if ((currentPage * pageSize) < this.totalCount && this.totalCount != 0) {
        this.searchForm.currentPage++
      }
      if ((currentPage * pageSize) >= this.totalCount) {
        this.finished = true
      } else {
        this.finished = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
  #charge-page.charge-page {
    padding: 10px;
    background-color: #fff;
    overflow: hidden;
    .title {
      margin-bottom: 10px;
      font-weight: 600;
    }
    .prview {
      img {
        width: 100%;

      }
    }
    .search-container {
      display: flex;
      .address {
        width: 40%;
      }
      .search-input {
        flex: 1;
        padding: 0;
      }
    }
    .list-wrapper {
      // 100vh - title - background - search
      height: calc(100vh - 30px - 300px - 40px);
      overflow-y: auto;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #eee;
        &:last-child {
          border-bottom: none;
        }
        .left {
          flex: 1;
          width: 70%;
          .address {
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
          }
          .meta {
            margin-top: 4px;
            span {
              font-size: 11px;
              color: rgba(0,0,0, .56);
              margin-right: 10px;
            }
          }
        }
        .right {
          width: 50px;
          flex-shrink: 0;
        }
      }
      .empty {
        text-align: center;
        color: #ccc;
      }
    }
  }
</style>
  